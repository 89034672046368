<template>
  <div class="gallery">
    <GalleryCard v-for="image in images" :key="image.id" :image="image" />
  </div>
</template>

<script>
import GalleryCard from "../components/GalleryCard";
export default {
  name: "Gallery",
  components: {
    GalleryCard,
  },
  data: function () {
    return {
      images: [
        {
          id: 1,
          path:
            "https://cdna.artstation.com/p/assets/images/images/027/392/624/large/eddie-yu-asset.jpg?1591393050",
        },
        {
          id: 2,
          path:
            "https://images.squarespace-cdn.com/content/v1/5ba81829b91449215ec2843a/1605406255986-KWE54QSLD3BEABITKLC2/ke17ZwdGBToddI8pDm48kH5jeowpRC5g3qosiEwbHMp7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z4YTzHvnKhyp6Da-NYroOW3ZGjoBKy3azqku80C789l0gmXcXvEVFTLbYX9CdVcGe5A_X_baWwCoOf6f-sZiBp2ro4ZTKFLUdIY8OIHAdfZ-w/P-046-%E8%B7%91%E9%A6%AC%E5%9C%B0A21+%282%29.jpg?format=2500w",
        },
        {
          id: 3,
          path:
            "https://cdn.asiatatler.com/asiatatler/i/hk/2020/09/03115917-home-interior-design-trends-autumn-winter-2020_cover_1049x701.jpeg",
        },
        {
          id: 4,
          path: "https://www.frameweb.com/assets/thumb/64680_55808_d4a6727.jpg",
        },
        {
          id: 5,
          path:
            "https://images.squarespace-cdn.com/content/v1/5e55a025353c683e9c44814f/1584922805350-PK6702D8R43HR810Q2OC/ke17ZwdGBToddI8pDm48kFWxnDtCdRm2WA9rXcwtIYR7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UcTSrQkGwCGRqSxozz07hWZrYGYYH8sg4qn8Lpf9k1pYMHPsat2_S1jaQY3SwdyaXg/modern-clean-open-concept-living-room-kitchen-interior-design-columbus-ohio-3.jpg?format=2500w",
        },
        {
          id: 6,
          path:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPvBGRo4o65NS3ITIahvO3LTElRzZ5zMAcuZOnZ-6QKk9qAKeJt8tdFsvNhUPeXnhrt3c&usqp=CAU",
        },
        {
          id: 11,
          path:
            "https://cdna.artstation.com/p/assets/images/images/027/392/624/large/eddie-yu-asset.jpg?1591393050",
        },
        {
          id: 12,
          path:
            "https://images.squarespace-cdn.com/content/v1/5ba81829b91449215ec2843a/1605406255986-KWE54QSLD3BEABITKLC2/ke17ZwdGBToddI8pDm48kH5jeowpRC5g3qosiEwbHMp7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z4YTzHvnKhyp6Da-NYroOW3ZGjoBKy3azqku80C789l0gmXcXvEVFTLbYX9CdVcGe5A_X_baWwCoOf6f-sZiBp2ro4ZTKFLUdIY8OIHAdfZ-w/P-046-%E8%B7%91%E9%A6%AC%E5%9C%B0A21+%282%29.jpg?format=2500w",
        },
        {
          id: 13,
          path:
            "https://cdn.asiatatler.com/asiatatler/i/hk/2020/09/03115917-home-interior-design-trends-autumn-winter-2020_cover_1049x701.jpeg",
        },
        {
          id: 14,
          path: "https://www.frameweb.com/assets/thumb/64680_55808_d4a6727.jpg",
        },
        {
          id: 15,
          path:
            "https://images.squarespace-cdn.com/content/v1/5e55a025353c683e9c44814f/1584922805350-PK6702D8R43HR810Q2OC/ke17ZwdGBToddI8pDm48kFWxnDtCdRm2WA9rXcwtIYR7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UcTSrQkGwCGRqSxozz07hWZrYGYYH8sg4qn8Lpf9k1pYMHPsat2_S1jaQY3SwdyaXg/modern-clean-open-concept-living-room-kitchen-interior-design-columbus-ohio-3.jpg?format=2500w",
        },
        {
          id: 16,
          path:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQPvBGRo4o65NS3ITIahvO3LTElRzZ5zMAcuZOnZ-6QKk9qAKeJt8tdFsvNhUPeXnhrt3c&usqp=CAU",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.gallery {
  background-color: $color-background;
  padding: 10px;
  display: flex;
  flex-flow: row wrap;
  margin-top: 3px;
  justify-content: center;
}

.gallery > div {
  flex: 0 0 30%;
}
</style>
