<template>
  <div class="gallery-item">
    <div class="gallery-item-container">
      <div class="web-btn-container">
        <img
          src="@/assets/click.svg"
          alt="source website button"
          class="web-icon"
        />
        <div class="web-text">abc.com</div>
      </div>
      <div v-on:click="goBack()" class="close-icon">
        <img src="@/assets/close.svg" alt="close image button" />
      </div>
      <img
        alt="image"
        src="https://www.frameweb.com/assets/thumb/64680_55808_d4a6727.jpg"
        class="image"
      />
      <div class="content">
        <div class="content-item">
          <div class="title-favorite-row">
            <div class="title">Header</div>
            <img
              src="@/assets/passion.svg"
              alt="favorite image button"
              class="favorite-icon"
            />
          </div>
          <img
            alt="image"
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlrssbls_5bhjlT3LhG8O3OteFF0iGJiiuPA&usqp=CAU"
            class="curtain-header"
          />
        </div>
        <div class="content-item">
          <div class="title">Fibric</div>
          <div class="fibric-container">
            <img
              alt="image"
              src="https://www.candh.co.uk/images/sanderson-home-lowen-dhpu236452-flint-fabric-p27259-29874_image.jpg"
              class="fibric-image"
            />
            <img
              alt="image"
              src="https://media.istockphoto.com/photos/beige-fabric-texture-background-picture-id516103720"
              class="fibric-image"
            />
            <img
              alt="image"
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSWr69sKYTQw54PgSl9tfOOBtLXp0edcaGl2g&usqp=CAU"
              class="fibric-image"
            />
            <img
              alt="image"
              src="https://www.candh.co.uk/images/sanderson-home-lowen-dhpu236452-flint-fabric-p27259-29874_image.jpg"
              class="fibric-image"
            />
          </div>
        </div>
      </div>
      <div class="button-container">
        <router-link :to="{ name: 'DesignRoom' }">
        <Button text="Start My Design" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../components/Button";

export default {
  components: {
    Button,
  },
  methods: {
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style scoped lang="scss">
.gallery-item {
  padding: 20px;
  display: flex;
  justify-content: center;
}

.gallery-item-container {
  max-width: 500px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: relative;
}

.image {
  max-width: 100%;
  height: auto;
  //filter: hue-rotate(200deg);
  border-radius: 20px;
}

.web-btn-container {
  padding: 5px 3px;
  border-radius: $radius;
  display: flex;
  flex-direction: row;
  margin-top: -6px;
  margin-bottom: 10px;
}

.web-icon {
  margin-right: 5px;
}

.web-text {
  font-size: 18px;
  line-height: 28px;
}

.favorite-icon {
  margin-right: 5px;
  margin-top: 5px;
}

.title-favorite-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.content-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.title {
  text-align: left;
  margin: 20px 0px 0px;
  font-family: $font-family-title;
  font-size: 20px;
  font-weight: 600;
  padding-left: 5px;
}

.curtain-header {
  max-width: 400px;
  margin: 5px;
  border-radius: $radius;
}

.fibric-container {
  display: flex;
  flex-direction: row;
}

.fibric-image {
  height: 100px;
  margin: 5px;
  border-radius: $radius;
}

.button-container {
  display: flex;
  justify-content: center;
  margin: 24px 0px 6px;
}

.close-icon {
  position: absolute;
  top: 26px;
  right: 26px;
}
</style>
