<template>
  <div class="design-room">
    <div class="sidenav">
      <div v-if="step === STEP_CONST.FABRIC" class="fabric-container">
        <div class="back-btn" v-on:click="selectStep('')">BACK</div>
        <img src="/fabric/1.jpg" class="fabric-item" v-on:click="selectMaterial('/fabric/1.jpg')"/>
        <img src="/fabric/2.jpg" class="fabric-item" v-on:click="selectMaterial('/fabric/2.jpg')"/>
        <img src="/fabric/3.jpg" class="fabric-item" v-on:click="selectMaterial('/fabric/3.jpg')"/>
        <img src="/fabric/4.jpg" class="fabric-item" v-on:click="selectMaterial('/fabric/4.jpg')"/>
        <img src="/fabric/5.jpg" class="fabric-item" v-on:click="selectMaterial('/fabric/5.jpg')"/>
        <img src="/fabric/6.jpg" class="fabric-item" v-on:click="selectMaterial('/fabric/6.jpg')"/>
        <img src="/fabric/7.jpg" class="fabric-item" v-on:click="selectMaterial('/fabric/7.jpg')"/>
        <img src="/fabric/8.jpg" class="fabric-item" v-on:click="selectMaterial('/fabric/8.jpg')"/>
        <img src="/fabric/9.jpg" class="fabric-item" v-on:click="selectMaterial('/fabric/9.jpg')"/>
        <img src="/fabric/10.jpg" class="fabric-item" v-on:click="selectMaterial('/fabric/10.jpg')"/>
        <img src="/fabric/11.jpg" class="fabric-item" v-on:click="selectMaterial('/fabric/11.jpg')"/>
        <img src="/fabric/12.jpg" class="fabric-item" v-on:click="selectMaterial('/fabric/12.jpg')"/>
      </div>
      <div v-if="step === STEP_CONST.HEADER" class="model-container">
        <div class="back-btn" v-on:click="selectStep('')">BACK</div>
        <div v-on:click="selectModel(1)"> Header 1</div>
        <div v-on:click="selectModel(2)"> Header 2</div>
      </div>
      <div v-if="step === ''" class="sidebar-menu">
        <div
          v-bind:class="[
            'sidenav-row',
            step === STEP_CONST.HEADER ? 'sidenav-row-selected' : '',
          ]"
          v-on:click="selectStep(STEP_CONST.HEADER)"
        >
          <div class="sidenav-row-inner">
            <div>Header</div>
            <div>Value</div>
          </div>
        </div>
        <div
          v-bind:class="[
            'sidenav-row',
            step === STEP_CONST.FABRIC ? 'sidenav-row-selected' : '',
          ]"
          v-on:click="selectStep(STEP_CONST.FABRIC)"
        >
          <div class="sidenav-row-inner">
            <div>Fibric</div>
            <div>Value</div>
          </div>
        </div>
        <div
          v-bind:class="[
            'sidenav-row',
            step === STEP_CONST.DRAPE_SIZE ? 'sidenav-row-selected' : '',
          ]"
          v-on:click="selectStep(STEP_CONST.DRAPE_SIZE)"
        >
          <div class="sidenav-row-inner">
            <div>Drape Size</div>
            <div>Value</div>
          </div>
        </div>
        <div
          v-bind:class="[
            'sidenav-row',
            step === STEP_CONST.LINING ? 'sidenav-row-selected' : '',
          ]"
          v-on:click="selectStep(STEP_CONST.LINING)"
        >
          <div class="sidenav-row-inner">
            <div>Lining</div>
            <div>Value</div>
          </div>
        </div>
        <div
          v-bind:class="[
            'sidenav-row',
            step === STEP_CONST.HARDWARE ? 'sidenav-row-selected' : '',
          ]"
          v-on:click="selectStep(STEP_CONST.HARDWARE)"
        >
          <div class="sidenav-row-inner">
            <div>Hardware</div>
            <div>Value</div>
          </div>
        </div>
      </div>
    </div>
    <div class="main" id="main" v-on:click="updateMaterial()"></div>
  </div>
</template>

<script>
import * as THREE from "three";
//import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
//import HdrFile from "@/assets/photo_studio_01_1k.hdr";
//import ModelFile from "@/assets/1.glb";
//import ModelFile1 from "@/assets/3.glb";
import ModelFile2 from "@/assets/4.glb";
let showroom, model1;
let camera, scene, renderer, controls;
//let pmremGenerator, current_object;
let topHeightPadding = 72;
let sidebarWidth = 400;
let pmremGenerator;
var textureLoader = new THREE.TextureLoader();
var loader = new GLTFLoader();

function render() {
  renderer.render(scene, camera);
}

function animate() {
  //requestAnimationFrame(animate);
  render();
}

export default {
  mounted() {
    showroom = document.createElement("div");
    document.getElementById("main").appendChild(showroom);

    scene = new THREE.Scene();
    camera = new THREE.PerspectiveCamera(
      70,
      (window.innerWidth - sidebarWidth) / window.innerHeight,
      0.01,
      200
    );

    camera.position.z = 5;
    const directionalLight = new THREE.DirectionalLight(0xdddddd, 1);
    directionalLight.position.set(0, 0, 1).normalize();
    scene.add(directionalLight);

    var ambientLight = new THREE.AmbientLight(0x222222);
    scene.add(ambientLight);
    //

    renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth - sidebarWidth, window.innerHeight-topHeightPadding);
    showroom.appendChild(renderer.domElement);

    pmremGenerator = new THREE.PMREMGenerator(renderer);
    pmremGenerator.compileEquirectangularShader();

    controls = new OrbitControls(camera, renderer.domElement);
    controls.addEventListener("change", render);
    controls.minDistance = 2;
    controls.maxDistance = 10;
    controls.target.set(0, 0, -0.2);

    window.addEventListener("resize", () => {
      renderer.setSize(window.innerWidth - sidebarWidth, window.innerHeight -topHeightPadding);
      camera.aspect = (window.innerWidth - sidebarWidth) / (window.innerHeight-topHeightPadding);
      camera.updateProjectionMatrix();
      animate();
    });

    this.loadModel();
  },
  data() {
    return {
      STEP_CONST: {
        HEADER: "header",
        FABRIC: "fabric",
        DRAPE_SIZE: "size",
        LINING: "lining",
        HARDWARE: "hardware",
      },
      step: "fabric",
      selected: {
        fabric: "/fabric/1.jpg",
        model: ModelFile2,
      }
    };
  },
  methods: {
    selectStep(s) {
      this.step = s;
      console.log("this.step: " + this.step);
    },
    selectModel(mf) {
      if (mf == 1) {
        this.selected.model = ModelFile2;
      }
      else {
        this.selected.model = ModelFile2;
      }
      scene.clear();
      this.loadModel();
    },
    loadModel() {
    /*
      loader.load(ModelFile, (gltf) => {
        model = gltf.scene;
        model.position.set(1, 0, 0);
        model.traverse((o) => {
          if (o.isMesh) {
            textureLoader.load("logo.png", (texture) => {
              o.material = new THREE.MeshBasicMaterial({ map: texture });
            });
          }
        });
        scene.add(model);
      });
*/
      loader.load(this.selected.model, (gltf) => {
        model1 = gltf.scene;
        model1.position.set(0, 0, 0);
        this.updateMaterial();
        scene.add(model1);
      });
    },
    selectMaterial(m) {
    console.log("selsect material " + m);
      this.selected.fabric = m;
      this.updateMaterial();
    },
    updateMaterial() {
      model1.traverse((o) => {
        if (o.isMesh && this.selected.fabric !== "") {
        console.log("this.selected.fabric: " + this.selected.fabric);
          textureLoader.load(this.selected.fabric, (texture) => {
            o.material = new THREE.MeshBasicMaterial({ map: texture });
            texture.repeat.set(3, 3);
            texture.wrapS = THREE.RepeatWrapping;
            texture.wrapT = THREE.RepeatWrapping;
            animate();
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.design-room {
}
.sidenav {
  height: 100%;
  width: 400px;
  position: fixed;
  z-index: 1;
  top: $top-nav-height;
  left: 0;
  background-color: $color-background;
  overflow-x: hidden;
  -moz-box-shadow: -3px 0 5px 0 #555;
  -webkit-box-shadow: -3px 0 5px 0 #555;
  box-shadow: -3px 0 5px 0 #555;
}

.sidenav .sidenav-row-inner:hover,
.sidenav .sidenav-row-selected .sidenav-row-inner {
  color: $color-primary;
  background-color: #ff748726;
}

.sidenav-row {
  padding: 4px 8px 4px 16px;
  text-decoration: none;
  font-size: 18px;
  color: $color-secondary;
}

.sidenav-row-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 14px;
  border-radius: 8px;
}

.main {
  margin-left: 400px; /* Same as the width of the sidenav */
  font-size: 28px; /* Increased text to enable scrolling */
  height: calc(100vh-#{$top-nav-height});
  //background-color: red;
  background: url("/background.jpg") no-repeat center fixed;
  background-size: contain;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav .sidenav-row {
    font-size: 18px;
  }
}

.sidebar-menu {
  padding-top: 16px;
}

.fabric-container {
  display: flex;
  flex-flow: row wrap;
}

.fabric-item {
  flex: 30%;
  width: 10px;
}

.back-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
  background: $color-background;
  padding: 10px;
  border-radius: 10px;
}
</style>
