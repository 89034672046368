<template>
  <div class="navbar" id="navbar">
    <router-link :to="{ name: 'Home' }">
      <img src="@/assets/logoWithText.svg" alt="logo" class="logo" />
    </router-link>
    <router-link :to="{ name: 'AI' }">
      <div class="navbar-item">{{ $t("topnav.ai") }}</div>
    </router-link>
    <router-link :to="{ name: 'Gallery' }">
      <div class="navbar-item">{{ $t("topnav.gallery") }}</div>
    </router-link>
    <router-link :to="{ name: 'DesignRoom' }">
      <div class="navbar-item">{{ $t("topnav.design") }}</div>
    </router-link>
    <!--div class="dropdown">
      <button class="dropbtn">
          <div class="navbar-item">
            Dropdown
            <i class="fa fa-caret-down"></i>
          </div>
      </button>
      <div class="dropdown-content">
        <a href="#">Link 1</a>
        <a href="#">Link 2</a>
        <a href="#">Link 3</a>
      </div>
    </div-->
    <a href="javascript:void(0);" class="icon" v-on:click="openMenu()">
      <img src="@/assets/menu_black_24dp.svg" alt="menu" class="menu-icon" />
    </a>
  </div>
  <div id="mySidenav" class="sidenav">
    <router-link :to="{ name: 'AI' }">
      <div class="navbar-item">{{ $t("topnav.ai") }}</div>
    </router-link>
    <router-link :to="{ name: 'Gallery' }">
      <div class="navbar-item">{{ $t("topnav.gallery") }}</div>
    </router-link>
    <router-link :to="{ name: 'DesignRoom' }">
      <div class="navbar-item">{{ $t("topnav.design") }}</div>
    </router-link>
  </div>
  <div
    class="sidebar-overlay"
    v-on:click="closeMenu()"
    style="cursor: pointer"
    id="myOverlay"
  ></div>
  <div class="main-content">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "TopNav",
  methods: {
    openMenu: () => {
      document.getElementById("mySidenav").style.width = "250px";
      document.getElementById("myOverlay").style.display = "block";
    },
    closeMenu: () => {
      document.getElementById("mySidenav").style.width = "0";
      document.getElementById("myOverlay").style.display = "none";
    },
  },
};
</script>

<style scoped lang="scss">
.navbar {
  overflow: hidden;
  background-color: $color-background;
  font-family: $font-family-title;
  font-weight: $font-weight-title;
  box-shadow: 0 1px 4px rgb(64 87 109 / 7%);
  padding: 0px 10px;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  height: $top-nav-height;
}

/* Links inside the navbar */
.navbar a {
  float: left;
  display: block;
  font-size: 16px;
  color: $color-secondary;
  text-align: center;
  padding: 18px 14px 8px;
  text-decoration: none;
  align-self: center;
}

.navbar a .logo {
  float: left;
  display: block;
  height: 40px;
  margin-right: 40px;
}

/* The dropdown container */
.dropdown {
  float: left;
  overflow: hidden;
  align-self: center;
  font-family: $font-family-title;
  font-weight: $font-weight-title;
}

/* Dropdown button */
.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: $color-secondary;
  padding: 10px 6px;
  background-color: inherit;
  margin: 0; /* Important for vertical align on mobile phones */
  font-family: $font-family-title;
  font-weight: $font-weight-title;
}

.navbar .navbar-item {
  padding: 6px 24px;
}

/* Add a red background color to navbar links on hover */
.navbar .navbar-item:hover {
  background-color: #40576d12;
  border-radius: $radius;
}

.router-link-active .navbar-item {
  background-color: #40576d12;
  border-radius: $radius;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: $color-background;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: $radius;
  padding: 10px;
  margin-top: -6px;
}

/* Links inside the dropdown */
.dropdown-content a {
  float: none;
  color: $color-secondary;
  padding: 4px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
  background-color: $color-background-gary;
  border-radius: $radius;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.navbar .icon {
  display: none;
  padding: 16px;
}

.navbar .icon .menu-icon {
  align-self: center;
  display: flex;
  justify-self: center;
}

@media screen and (max-width: 600px) {
  .navbar a:not(:first-child) {
    display: none;
  }
  .navbar .dropdown {
    display: none;
  }
  .navbar a.icon {
    float: right;
    display: block;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .navbar.responsive {
    position: relative;
  }
  .navbar.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .navbar.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  background-color: $color-background;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 20px;
}

.sidenav a {
  padding: 4px 8px;
  text-decoration: none;
  font-size: 18px;
  color: $color-secondary;
  display: block;
  transition: 0.3s;
  z-index: 5;
  font-family: $font-family-title;
  font-weight: $font-weight-title;
}

.sidenav .navbar-item {
  padding: 4px;
}

.sidenav .navbar-item:hover {
  background-color: $color-background-gary;
  border-radius: $radius;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

.sidebar-overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-background-dark;
  z-index: 2;
}

.main-content {
  padding-top: $top-nav-height;
}
</style>
