<template>
  <div class="gallery-card">
    <div class="gallery-item" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
      <img v-bind:src="image.path" class="image" />
      <div class="gallery-card-overlay" v-if="isHover">
        <div class="web-btn-container">
          <img
            src="@/assets/click.svg"
            alt="source website button"
            class="web-icon"
          />
          <div class="web-text">abc.com</div>
        </div>

        <img
          src="@/assets/passion.svg"
          alt="favorite image button"
          class="favorite-icon"
        />
        <router-link
          :to="{ name: 'GalleryItem', params: { id: 1 } }"
          class="curtain-btn-container"
        >
          <img
            src="@/assets/needle.svg"
            alt="make curtain button"
            class="curtain-icon"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "GalleryCard",
  props: {
    image: Object,
  },
  methods: {
    mouseEnter() {
      this.isHover = true;
    },
    mouseLeave() {
      this.isHover = false;
    },
  },
  data() {
    return {
      isHover: false,
    };
  },
};
</script>

<style scoped lang="scss">
.gallery-card {
  padding: 10px;
}

.gallery-item {
  border-radius: 10px;
  height: auto;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.gallery-card-overlay {
  width: 100%;
  height: 100%;
  background-color: $color-background-dark;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.web-btn-container {
  position: absolute;
  bottom: 12px;
  left: 10px;
  background-color: $color-background-with-hover;
  padding: 5px 10px;
  border-radius: $radius;
  display: flex;
  flex-direction: row;
}

.web-icon {
  margin-right: 5px;
}

.web-text {
  font-size: 18px;
  line-height: 28px;
}

.favorite-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.curtain-btn-container {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: $color-background-with-hover;
  border-radius: 36px;
  height: 36px;
  width: 36px;
}

.web-btn-container:hover,
.curtain-btn-container:hover {
  background-color: $color-background;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: block;
}

.curtain-icon {
  margin-top: 6px;
}
</style>
