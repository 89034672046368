<template>
  <div class="button">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "Button",
  props: ["text"],
};
</script>

<style lang="scss" scoped>
.button {
  padding: 10px 20px;
  min-width: 200px;
  background-color: $color-primary;
  color: $color-background;
  border-radius: $radius;
  font-size: 18px;
}
</style>
