<template>
  <div class="ai">
    <div v-if="step === 0" class="content-container content-container-0">
      <img
        src="@/assets/Blue-Robot.svg"
        alt="source website button"
        class="robot"
      />

      <div class="text-container">
        <div class="text-p">Hello, I am A.I. consultant Ivan.</div>
        <div class="text-p">
          I will help to choose a suitable curtain for you.
        </div>
        <div class="button-container">
          <Button v-on:click="goNextStep()" text="Let's start!" />
        </div>
      </div>
    </div>
    <div v-if="step === 1" class="content-container content-container-1">
      <div class="question-row">
        <img
          src="@/assets/Blue-Robot.svg"
          alt="source website button"
          class="robot-question"
        />
        <div class="question">Where are you going to install the curtain?</div>
      </div>
      <div class="answer-row">
        <div class="answer">
          <div
            v-bind:class="[
              'answer-item',
              q1 === 0 ? 'answer-item-selected' : '',
            ]"
            v-on:click="answerQ1(0)"
          >
            <img
              src="https://www.shilpakalainteriors.in/images/gallery/Kitchen/K(1).jpg"
              class="answer-item-img"
            />
            <div class="answer-item-desc">Kitchen</div>
          </div>
          <div
            v-bind:class="[
              'answer-item',
              q1 === 1 ? 'answer-item-selected' : '',
            ]"
            v-on:click="answerQ1(1)"
          >
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSS6c_5duKqY1K-W8-UcnSXFnQsoKnMTPmFZA&usqp=CAU"
              class="answer-item-img"
            />
            <div class="answer-item-desc">Bedroom</div>
          </div>
          <div
            v-bind:class="[
              'answer-item',
              q1 === 2 ? 'answer-item-selected' : '',
            ]"
            v-on:click="answerQ1(2)"
          >
            <img
              src="https://goodhomes.wwmindia.com/content/2020/mar/living-room-interior-design-11584362976.jpg"
              class="answer-item-img"
            />
            <div class="answer-item-desc">Living Room</div>
          </div>
        </div>

        <div class="button-container">
          <Button v-on:click="goNextStep()" text="Next" />
        </div>
      </div>
    </div>
    <div v-if="step === 2" class="content-container content-container-1">
      <div class="question-row">
        <img
          src="@/assets/Blue-Robot.svg"
          alt="source website button"
          class="robot-question"
        />
        <div class="question">Do you want to block the sunshine?</div>
      </div>
      <div class="answer-row">
        <div class="answer">
          <div class="answer-item">
            <Button v-on:click="goNextStep()" text="Yes" />
          </div>
          <div class="answer-item">
            <Button v-on:click="goNextStep()" text="No" />
          </div>
        </div>
      </div>
    </div>
    <div v-if="step === 3" class="content-container content-container-0">
      <img
        src="@/assets/Blue-Robot.svg"
        alt="source website button"
        class="robot"
      />

      <div class="text-container">
        <div class="text-p">
          Thanks for Ivan has built your desired curtain.
        </div>
        <div class="button-container">
          <router-link :to="{ name: 'DesignRoom' }">
          <Button text="Have a look!" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../components/Button";

export default {
  components: {
    Button,
  },
  methods: {
    goNextStep() {
      this.step = this.step + 1;
      console.log("step: " + this.step);
    },
    answerQ1(a) {
      console.log("answerQ1: " + a);
      this.q1 = a;
    }
  },
  data() {
    return {
      step: 0,
      q1: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.ai {
  //background-image: url("../assets/ai_background.svg");
  //background-size: cover;
  //background-position: bottom center;
  //background-repeat: no-repeat;
  background-color: #f1f1f2;
}

.robot {
  max-width: 500px;
}

.content-container {
  height: calc(100vh - 72px);
  display: flex;
}

.content-container-0 {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.text-container {
  max-width: 500px;
  background-color: white;
  padding: 30px;
  font-size: 20px;
  margin-left: 20px;
  border-radius: 20px;
  color: $color-secondary;
  margin-top: -50px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.text-p {
  margin: 12px 0;
  font-family: $font-family-title;
  font-weight: $font-weight-title;
}

.button-container {
  margin: 30px 20px 0px;
}

.content-container-1 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -50px;
}

.robot-question {
  height: 60px;
  margin-right: 20px;
}

.question-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.question {
  color: $color-secondary;
  font-size: 24px;
  font-family: $font-family-title;
  font-weight: $font-weight-title;
}

.answer-row {
  max-width: 500px;
  padding: 30px;
  font-size: 20px;
  margin-left: 20px;
  border-radius: 20px;
  color: $color-secondary;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.answer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.answer-item {
  margin: 0px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px solid transparent;
  padding: 6px;
  border-radius: $radius;
}

.answer-item:hover {
  border: 3px solid #e23c52b0;
  color: $color-primary;
}

.answer-item-selected {
  border: 3px solid #e23c52b0;
  color: $color-primary;
}

.answer-item-img {
  max-height: 100px;
  min-width: 160px;
  margin-bottom: 10px;
  border-radius: $radius;
}
</style>
