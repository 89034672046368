import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createI18n } from "vue-i18n";

const messages = {
  en: {
    topnav: {
      home: "Home",
      gallery: "Gallery",
      ai: "A.I.",
      design: "Design",
    },
    message: {
      hello: "hello world",
    },
  },
  ja: {
    topnav: {
      home: "Home",
      gallery: "Gallery",
      ai: "A.I.",
      design: "Design",
    },
    message: {
      hello: "こんにちは、世界",
    },
  },
};

// Create VueI18n instance with options
const i18n = createI18n({
  locale: "ja", // set locale
  messages, // set locale messages
});

createApp(App).use(store).use(router).use(i18n).mount("#app");
