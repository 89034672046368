<template>
  <div class="home">
    <img
      alt="banner"
      src="@/assets/data-science-icon-animation-banner-clockwise.gif"
      class="banner"
    />
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style scoped lang="scss">
.home {
  padding: 20px;
}

.banner {
  max-width: 100%;
  height: auto;
  //filter: hue-rotate(200deg);
  border-radius: 20px 20px 0px 0px;
}
</style>
